import React, { useState } from 'react';

import academicCap from '../assets/academic-cap.png';
import calendar from '../assets/calendar.png';
import chartBar from '../assets/chart-bar.png';
import checkCircle from '../assets/check-circle.png';
import locationMarker from '../assets/location-marker.png';
import headerImage from '../assets/courses.jpg';
import formImage from '../assets/form.jpg';

const iconMap = {
  "academic-cap": academicCap,
  "calendar": calendar,
  "chart-bar": chartBar,
  "check-circle": checkCircle,
  "location-marker": locationMarker,
};

function CourseCard({ course }) {
  return (
    <div className="bg-white p-4 sm:p-6 rounded-3xl shadow-lg flex flex-col w-full max-w-[320px] sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg h-[400px] justify-between">
      <div>
        <h2 className="text-lg md:text-xl font-bold mb-1 text-left">{course.title}</h2>
        <h3 className="text-sm md:text-base mb-4 text-gray-600 text-left">{course.university}</h3>
        <ul className="space-y-2 sm:space-y-3 md:space-y-4">
          {course.details.map((detail, index) => (
            <li key={index} className="flex items-center text-gray-700 text-xs sm:text-sm md:text-base">
              <img src={iconMap[detail.icon]} alt={detail.icon} className="w-4 h-4 sm:w-5 sm:h-5 mr-2 sm:mr-3" />
              {detail.text}
            </li>
          ))}
        </ul>
      </div>
      <button className="mt-4 md:mt-6 bg-amber-400 text-white py-2 px-4 md:py-3 md:px-6 rounded-full text-sm md:text-base font-semibold hover:bg-amber-500 transition-colors">
        Find out more
      </button>
    </div>
  );
}

function Courses() {
  const courses = [
    {
      title: "MBBS",
      university: "University of Birmingham",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 101" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Birmingham, United Kingdom" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "BSc Nursing",
      university: "University of Birmingham",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 101" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Birmingham, United Kingdom" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "Bachelor of Business Administration",
      university: "University of Birmingham",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 101" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Birmingham, United Kingdom" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "Master of Business Administration",
      university: "University of Birmingham",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 101" },
        { icon: "academic-cap", text: "Masters Degree" },
        { icon: "location-marker", text: "Birmingham, United Kingdom" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "MBBS",
      university: "Heidelberg University",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 54" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Heidelberg, Germany" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "BSc Nursing",
      university: "Heidelberg University",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 54" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Heidelberg, Germany" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "Bachelor of Business Administration",
      university: "Heidelberg University",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 54" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Heidelberg, Germany" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "Master of Business Administration",
      university: "Heidelberg University",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 54" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Heidelberg, Germany" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
    {
      title: "MBBS",
      university: "University of Belgrade",
      details: [
        { icon: "chart-bar", text: "The World Ranking : 54" },
        { icon: "academic-cap", text: "Bachelors Degree" },
        { icon: "location-marker", text: "Heidelberg, Germany" },
        { icon: "calendar", text: "01.08.2024" },
        { icon: "check-circle", text: "Entry Score : IELTS 6.5" },
      ],
    },
  ];
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    destination: '',
    studyTime: '',
    studyLevel: '',
    funding: '',
    agreeTerms: false
  });
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = 'First Name is required.';
    if (!formData.lastName) errors.lastName = 'Last Name is required.';
    if (!formData.email) errors.email = 'Email is required.';
    if (!formData.phone) errors.phone = 'Phone number is required.';
    if (!formData.agreeTerms) errors.agreeTerms = 'You must agree to the terms and conditions.';

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setError('');
    try {
      // Replace this with your actual form submission logic
      await new Promise(resolve => setTimeout(resolve, 1000));
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        destination: '',
        studyTime: '',
        studyLevel: '',
        funding: '',
        agreeTerms: false
      });
      alert('Thanks for contacting us, we will be in touch with you soon');
    } catch (error) {
      console.error('Submission error:', error);
      setError('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <div className="bg-white overflow-x-hidden">
      <header className="text-center mb-8 sm:mb-12">
        <img 
          src={headerImage} 
          alt="Header" 
          className="mx-auto mb-8 sm:mb-12 object-cover w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[522px]" 
        />
        <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 text-left px-4 sm:px-6 lg:px-8">
          Find courses to study abroad!
        </h1>
      </header>

      <main className="w-full bg-zinc-200">
        <div className="container mx-auto px-4 sm:px-6 py-8 lg:px-8 xl:px-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 justify-items-center">
            {courses.map((course, index) => (
              <CourseCard key={index} course={course} />
            ))}
          </div>

          <div className="flex justify-center items-center mt-6 space-x-2">
            <button className="px-3 py-1 bg-white rounded text-sm sm:text-base sm:px-4 sm:py-2">1</button>
            <button className="px-3 py-1 rounded text-sm sm:text-base sm:px-4 sm:py-2">2</button>
            <button className="px-3 py-1 rounded text-sm sm:text-base sm:px-4 sm:py-2">3</button>
          </div>
        </div>
      </main>

      <section className="min-h-screen flex flex-col lg:flex-row justify-between p-4 sm:p-6 md:p-8 lg:p-12">
        <div className="text-left max-w-4xl w-full lg:w-1/2 lg:pr-8 mt-8 lg:mt-16">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6">Talks Global can help you</h2>
          <p className="mb-8 text-base sm:text-lg md:text-xl">
            Enter your details and one of our expert counsellors will reach out to you so we can connect
            you to the right course, country, university – and even scholarships!
          </p>
          <form onSubmit={handleFormSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
                <label htmlFor="firstName" className="block mb-1">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded"
                />
                {validationErrors.firstName && <p className="text-red-500">{validationErrors.firstName}</p>}
              </div>
              <div>
                <label htmlFor="lastName" className="block mb-1">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded"
                />
                {validationErrors.lastName && <p className="text-red-500">{validationErrors.lastName}</p>}
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block mb-1">E-mail Address</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border-2 border-orange-200 rounded"
              />
              {validationErrors.email && <p className="text-red-500">{validationErrors.email}</p>}
            </div>
            <div>
              <label htmlFor="phone" className="block mb-1">Phone Number</label>
              <div className="flex">
                <input type="text" className="w-20 sm:w-24 p-2 border-2 border-orange-200 rounded-l text-center" value="+ 91" readOnly />
                <input
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded-r"
                />
              </div>
              {validationErrors.phone && <p className="text-red-500">{validationErrors.phone}</p>}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
                <label htmlFor="destination" className="block mb-1">Your preferred study destination</label>
                <select
                  id="destination"
                  value={formData.destination}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="germany">Germany</option>
                  <option value="serbia">Serbia</option>
                  <option value="uk">UK</option>
                  <option value="canada">Canada</option>
                </select>
              </div>
              <div>
                <label htmlFor="studyTime" className="block mb-1">When do you plan to study</label>
                <select
                  id="studyTime"
                  value={formData.studyTime}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
              <label htmlFor="studyLevel" className="block mb-1">Preferred study level</label>
                <select
                  id="studyLevel"
                  value={formData.studyLevel}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="undergraduate">Undergraduate</option>
                  <option value="postgraduate">Postgraduate</option>
                  <option value="phd">PhD</option>
                </select>
              </div>
              <div>
                <label htmlFor="funding" className="block mb-1">How would you fund your education</label>
                <select
                  id="funding"
                  value={formData.funding}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="self">Self-funded</option>
                  <option value="loan">Education Loan</option>
                  <option value="scholarship">Scholarship</option>
                </select>
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="agreeTerms"
                checked={formData.agreeTerms}
                onChange={handleChange}
                className="h-5 w-5 mr-3"
              />
              <label htmlFor="agreeTerms" className="text-sm sm:text-base">I agree to Talks Global Terms and Conditions</label>
            </div>
            {validationErrors.agreeTerms && <p className="text-red-500">{validationErrors.agreeTerms}</p>}
            {error && <p className="text-red-500">{error}</p>}
            <button type="submit" className="bg-amber-500 text-white py-3 px-10 sm:px-20 rounded-full w-full sm:w-auto text-base sm:text-lg">Submit</button>
          </form>
        </div>

        <div className="mt-8 lg:mt-0 lg:ml-8 mb-6 lg:mb-0">
          <img src={formImage} alt="" className="object-cover rounded-lg w-full max-w-[440px] h-auto sm:h-[510px]" />
        </div>
      
      </section>
    </div>
  );
}

export default Courses;