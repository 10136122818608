import React, { useState } from 'react';
import ContactImg from '../assets/contact.jpeg';

const handleSubmit = async (e, formData, setError) => {
  // Implement your form submission logic here
  // For now, let's just log the form data
  console.log('Form submitted:', formData);
  // You can add your actual submission logic here later
};

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    destination: '',
    studyTime: '',
    studyLevel: '',
    funding: '',
    agreeTerms: false
  });
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: type === 'checkbox' ? checked : value
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.firstName) errors.firstName = 'First Name is required.';
    if (!formData.lastName) errors.lastName = 'Last Name is required.';
    if (!formData.email) errors.email = 'Email is required.';
    if (!formData.phone) errors.phone = 'Phone number is required.';
    if (!formData.agreeTerms) errors.agreeTerms = 'You must agree to the terms and conditions.';

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setError('');
    try {
      await handleSubmit(e, formData, setError);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        destination: '',
        studyTime: '',
        studyLevel: '',
        funding: '',
        agreeTerms: false
      });
      alert('Thanks for contacting us, we will be in touch with you soon');
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  return (
    <div className="w-full md:w-1/2 p-4 md:p-8">
      <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6">Talks Global can help you</h2>
      <p className="mb-6 md:mb-8 text-base md:text-lg">
        Enter your details and one of our expert counsellors will reach out to you so we can connect you to the right course, country, university – and even scholarships!
      </p>
      <form onSubmit={handleFormSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
                <label htmlFor="firstName" className="block mb-1">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded"
                />
                {validationErrors.firstName && <p className="text-red-500">{validationErrors.firstName}</p>}
              </div>
              <div>
                <label htmlFor="lastName" className="block mb-1">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded"
                />
                {validationErrors.lastName && <p className="text-red-500">{validationErrors.lastName}</p>}
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block mb-1">E-mail Address</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border-2 border-orange-200 rounded"
              />
              {validationErrors.email && <p className="text-red-500">{validationErrors.email}</p>}
            </div>
            <div>
              <label htmlFor="phone" className="block mb-1">Phone Number</label>
              <div className="flex">
                <input type="text" className="w-20 sm:w-24 p-2 border-2 border-orange-200 rounded-l text-center" value="+ 91" readOnly />
                <input
                  type="text"
                  id="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded-r"
                />
              </div>
              {validationErrors.phone && <p className="text-red-500">{validationErrors.phone}</p>}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
                <label htmlFor="destination" className="block mb-1">Your preferred study destination</label>
                <select
                  id="destination"
                  value={formData.destination}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="germany">Germany</option>
                  <option value="serbia">Serbia</option>
                  <option value="uk">UK</option>
                  <option value="canada">Canada</option>
                </select>
              </div>
              <div>
                <label htmlFor="studyTime" className="block mb-1">When do you plan to study</label>
                <select
                  id="studyTime"
                  value={formData.studyTime}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
              <div>
                <label htmlFor="studyLevel" className="block mb-1">Preferred study level</label>
                <select
                  id="studyLevel"
                  value={formData.studyLevel}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="undergraduate">Undergraduate</option>
                  <option value="postgraduate">Postgraduate</option>
                  <option value="phd">PhD</option>
                </select>
              </div>
              <div>
                <label htmlFor="funding" className="block mb-1">How would you fund your education</label>
                <select
                  id="funding"
                  value={formData.funding}
                  onChange={handleChange}
                  className="w-full p-2 border-2 border-orange-200 rounded bg-white text-base"
                >
                  <option value="">Select</option>
                  <option value="self">Self-funded</option>
                  <option value="loan">Education Loan</option>
                  <option value="scholarship">Scholarship</option>
                </select>
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="agreeTerms"
                checked={formData.agreeTerms}
                onChange={handleChange}
                className="h-5 w-5 mr-3"
              />
              <label htmlFor="agreeTerms" className="text-sm sm:text-base">I agree to Talks Global Terms and Conditions</label>
            </div>
            {validationErrors.agreeTerms && <p className="text-red-500">{validationErrors.agreeTerms}</p>}
            {error && <p className="text-red-500">{error}</p>}
            <button type="submit" className="bg-amber-500 text-white py-3 px-10 sm:px-20 rounded-full w-full sm:w-auto text-base sm:text-lg">Submit</button>
          </form>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="bg-white overflow-x-hidden">
      <div className="relative mb-6 md:mb-10 mx-auto w-full max-w-[95%] md:max-w-[90%] lg:max-w-[80%] xl:max-w-[1580px]">
        <img 
          src={ContactImg} 
          alt="Contact" 
          className="w-full h-[300px] md:h-[400px] lg:h-[500px] rounded-[16px] md:rounded-[32px] mt-4 md:mt-8 lg:mt-14 object-cover"
        />
      </div>
      <div className="min-h-screen flex items-center justify-center px-4 py-8">
        <div className="container mx-auto">
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 p-4 md:p-8">
              <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 md:mb-6">Have Questions?<br/>Don't Hesitate to Contact Us</h1>
              <p className="mb-4 md:mb-6 text-sm sm:text-base md:text-lg">
                Our team is here to help! Whether you need more information or have specific questions, feel free to reach out to us anytime.
              </p>
              <div className="space-y-4 p-2 md:p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8 bg-purple-100 rounded-full flex items-center justify-center mr-3">
                    <svg className="w-4 h-4 md:w-5 md:h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </div>
                  <div>
                    <span className="text-purple-600 font-semibold text-xs sm:text-sm md:text-base">Location</span>
                    <p className="text-gray-700 text-xs sm:text-sm md:text-base">kerala, india</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <div className="flex-shrink-0 w-6 h-6 md:w-8 md:h-8 bg-purple-100 rounded-full flex items-center justify-center mr-3">
                    <svg className="w-4 h-4 md:w-5 md:h-5 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                  </div>
                  <div>
                    <span className="text-purple-600 font-semibold text-xs sm:text-sm md:text-base">Quick Contact</span>
                    <p className="text-gray-700 text-xs sm:text-sm md:text-base">Phone: +91 1234567890</p>
                    <p className="text-gray-700 text-xs sm:text-sm md:text-base">E-mail: talksglobal@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;