import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BgTexture from '../assets/logo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-[#E5E5E5] w-full">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-[120px]">
          <div className="flex-shrink-0 flex items-center -mt-2">
            <img className="h-20 w-auto md:h-32 lg:h-40" src={BgTexture} alt="Logo" />
          </div>

          <div className="hidden md:flex md:items-center w-full justify-between ml-4 lg:ml-20 -mr-4 mt-2 bg-white h-[95px] rounded-[25px]">
            <div className="flex items-center space-x-2 lg:space-x-20 ml-4 mt-4">
              <Link to="/" className="text-gray-900 hover:text-purple-600 px-3 py-2 text-sm font-medium">Home</Link>
              <Link to="/countries" className="text-gray-500 hover:text-purple-600 px-3 py-2 text-sm font-medium">Countries</Link>
              <Link to="/courses" className="text-gray-500 hover:text-purple-600 px-3 py-2 text-sm font-medium">Courses</Link>
              <Link to="/contact" className="text-gray-500 hover:text-purple-600 px-3 py-2 text-sm font-medium">Contact us</Link>
              <Link to="/about" className="text-gray-500 hover:text-purple-600 px-3 py-2 text-sm font-medium">About us</Link>
            </div>
            <div className="flex items-center justify-end mt-2 mr-4">
              <a href="tel:+911234567890" className="flex items-center text-sm font-medium">
                <span className="text-purple-600 mr-2">Enquiries:</span>
                <span className="text-gray-500">:+91 6235423350</span>
              </a>
            </div>
          </div>

          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-600"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="/" className="text-gray-500 hover:text-purple-600 hover:bg-gray-50 block px-3 py-2 rounded-md text-base font-medium">Home</Link>
          <Link to="/countries" className="text-gray-500 hover:bg-gray-50 hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium">Countries</Link>
          <Link to="/courses" className="text-gray-500 hover:bg-gray-50 hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium">Courses</Link>
          <Link to="/contact" className="text-gray-500 hover:bg-gray-50 hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium">Contact us</Link>
          <Link to="/about" className="text-gray-500 hover:bg-gray-50 hover:text-purple-600 block px-3 py-2 rounded-md text-base font-medium">About us</Link>
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4">
            <a href="tel:+916235423350" className="flex items-center text-sm font-medium">
              <span className="text-purple-600">Enquiries:</span>
              <span className="text-gray-500 ml-2">+91 6235423350</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;